<template>
  <div>
    <CCard class="col-md-12">
      <CRow class="p-3">
        <h4 class="color-light ml-2 page_title"><span class="ul_text">Vi</span>DEO LIST</h4> 
      </CRow>
      <CCardBody>
        <CRow class="col-md-12 col-12">
          <CCol class="col-md-4 col-12">
            <CRow>
                <label class="">Date Range</label>
             </CRow>
            <CRow class="">
              <span class="form-control" @click="()=>date_modal=true" style="cursor: pointer;">
                {{ changeDateFormate(startDate)}}   <b>-</b>  {{ changeDateFormate(endDate) }}
              </span>
              <CModal
                title="Select Date Range"
                :show.sync="date_modal"
                :centered="true"
                scrollable
                color="dark"
                
              >
              <date-range-picker 
                :from="date_data.endDate"
                :to="date_data.startdate"
                :future="false"
                :panel="date_data.panel"
                :showControls="false" 
                @select="update"/>
              <template #footer>
                <CButton @click="date_modal = false" color="danger">Close</CButton>
              </template>
              </CModal>
              <!-- <date-time-picker
                class="col-12"
                :endDate="endDate"
                :singleDate="false"
                :startDate="startDate"
                @onChange="dateFilter"
              /> -->
            </CRow>
          </CCol>
          <CCol class="col-md-3 col-12 ml-md-3">
            <CRow class="">
              <label class="">Campaign</label>
            </CRow>
            <CRow class="">
              <multiselect
                class=""
                v-model="input.campid"
                placeholder="Campaign:"
                label="name"
                track-by="_id"
                :options="camp_list"
                :multiple="false"
                :taggable="false"
                :preselect-first="true"
                :close-on-select="true"
              >
              </multiselect>
            </CRow>
          </CCol>
          <CCol class="col-md-2 col-12 ml-md-3">
            <CRow class="">
              <label class="">Device Type</label>
            </CRow>
            <CRow>
              <multiselect
                class=""
                v-model="input.devicetype"
                tag-placeholder="Add this as new tag"
                placeholder="Device Type:"
                :options="devicetypeopt"
                :multiple="false"
                :taggable="false"
                :preselect-first="true"
                :close-on-select="true"
              >
              </multiselect>
            </CRow>
          </CCol>
          <CCol class="ml-md-3">
            <CRow><label class="p-2"></label></CRow>
            <CRow class="mt-2">
              <CButton
                class=""
                size=""
                type="submit"
                @click="onSubmit()"
                variant=""
                color="success"
                >Search</CButton
              >
            </CRow>
          </CCol>
        </CRow>
        <div class="card-header-actions pb-2">
          <small class="text-muted">
            <multiselect
              size="sm"
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect
          ></small>
        </div>
        <div v-if="loading_data==true" class="p-5">
          <center> Loading . . .</center>
          </div>
        <div v-else>
        <!-- Main table element -->
        <CDataTable
          :items="items"
          :fields="fields"
          :dark="false"
          :items-per-page="tableperpage"
          sorter
          hover
          striped
          border
          small
          fixed
          column-filter
          light
        >
        <template #action="{item}">
            <td>
                <img @click="Redirect(item)"
                  src="img/logo/icn-2.png" class="img-fluid ml-1" title="Videos"  style="cursor: pointer;"
            />
            </td>
        </template>
        </CDataTable>
        </div>
        <div class="pagination">
          <a href="#" @click="onSubmit('prev')">&laquo;</a>
          <a href="#" class="active">{{ pageno }}</a>
          <a href="#" @click="onSubmit('next')">&raquo;</a>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
import CampaignControlService from "../../services/campaignControlService";
import ReportsController from "../../services/reportsController";
import Multiselect from "vue-multiselect";
export default {
  name: "VideoList",
  components: {
    DateTimePicker,
    Multiselect,
  },
  data() {
    return {
      date_data:{startdate:null,enddate:null,panel:null},
      date_modal:false,
      tableperpage: 20,
      perPage: [20, 50, 100],
      pageno: 1,
      items: [],
      fields: [
        { key: "_id", label: "Session Id",sortable: true },
        { key: "time", label: "Time",sortable: true },
        { key: "external_id", label: "External Id",sortable: true },
        { key: "campid", label: "Campaign Id",sortable: true },
        { key: "devicetype", label: "Device Type",sortable: true },
        { key: "action", label: "Action" }
        ],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      startDate: new Date(),
      endDate: new Date(),
      input: {
        edate: new Date(),
        sdate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        campid: "",
        devicetype: "",
      },
      camp_list: [],
      devicetypeopt: ["Desktop", "SmartPhone"],
      loading_data: false,
    };
  },
  mounted() {
    if (
      window.localStorage.getItem("user") &&
      window.localStorage.getItem("status") != "true" || window.localStorage.getItem("iwitness") == 'false'
    ) {
      this.$router.push("/");
    } else {
      if (window.localStorage.getItem("user") == "null") {
        alert("Please Enter User Name and Password");
        this.$router.push("Login");
      } else {
        this.dateFilter({startDate:new Date(this.startDate.setHours(0,0,0,0)),endDate:new Date(this.endDate.setHours(23,59,0,0))});
        if(this.$root.$data.videolistdata){
        this.input=this.$root.$data.videolistdata;
        this.onSubmit();
        }
        this.getcampList();
      }
    }
  },
  methods: {
    changeDateFormate(d){
      var p = new Date(d);
      return p.toLocaleDateString("en-US", {day: "numeric",year: "numeric",month: "long"})
    },
    update(values) {
      this.date_data.startdate = values.from
      this.date_data.enddate = values.to
      this.date_data.panel = values.panel
      this.dateFilter({startDate:new Date(values.from),endDate:new Date(values.to)})
      this.date_modal = false;
    },
    Redirect(data){
      this.$root.$data.iwitness_id = data.external_id
      this.$router.push("/campaign/iwitness");
    },
    checkPage() {
      localStorage.setItem("log_table", this.tableperpage);
      this.onSubmit();
    },
    async onSubmit(txt) {
      if (txt == "next") {
        this.pageno++;
      }
      if (txt == "prev") {
        if (this.pageno != 1) {
          this.pageno--;
        }
      }
      if (this.validate()) {
        let payload = {
          pageSize: this.tableperpage,
          currentPage: this.pageno,
          filters: {
            sdate: this.input.sdate,
            edate: this.input.edate,
            campid: this.input.campid._id,
            devicetype: this.input.devicetype,
          },
        };
        this.$root.$data.videolistdata = this.input;
        try {
          this.loading_data = true;
          let response = await ReportsController.getvideolist(payload);
          if (response.result) {
            this.items = response.message
          }
          this.loading_data = false;
        } catch (e) {
          // alert("connection Error!");
        }
      } else {
        alert("Please Select Campaign");
      }
    },
    validate() {
      if (this.input.campid == "") {
        return false;
      }
      return true;
    },
    async getcampList() {
      var response = await CampaignControlService.get();
      var campaign = response.message.campaigns;
      campaign.map((t) => {
        this.camp_list.push({
          _id: t._id,
          name: t.campname,
        });
      });
    },
    dateFilter(data) {
      this.input.sdate = new Date(
        data.startDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.input.edate = new Date(
        data.endDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.startDate = data.startDate;
      this.endDate = data.endDate;
    },
  },
};
</script>


<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  font-size: 22px;
}

.pagination a.active {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}
</style>